<template>
  <div>
    <Accounts />
    <Tabs />
    <div class="text-center">
      <p class="italic">“The Live Account Summary is as of midnight today. Any allocations today on the account will only
        reflect tomorrow”</p>
    </div>
    <div class="download-button-container">
      <v-btn class="download-button" color="primary" dark @click="downloadDocuments">
        <span v-if="!loadingDocuments">Download Documents</span>
        <span v-else>
          <v-progress-circular indeterminate size="24"></v-progress-circular>
          Downloading...
        </span>
      </v-btn>
    </div>
  </div>
</template>
<script>

import Tabs from "@/components/Tabs";
import Accounts from "@/components/Accounts";

export default {
  name: 'Dashboard',
  components: { Accounts, Tabs },
  data() {
    return {
      loadingDocuments: false
    };
  },
  async beforeCreate() {
    await this.$store.dispatch('fetchAccountDetails');
  },
  async created() {
    if (!this.$store.getters.isAuthenticated) {
      await this.$router.push('/login');
    }
  },
  methods: {
    downloadDocuments() {
      this.loadingDocuments = true;
      this.$store.dispatch('downloadAllDocuments')
        .then(() => {
          // Handle success if needed
        })
        .catch(error => {
          console.error('Error downloading documents:', error);
        })
        .finally(() => {
          this.loadingDocuments = false;
        });
    }
  }
}
</script>

<style scoped>
.text-center {
  text-align: center;
}

.italic {
  font-style: italic;
}

.download-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.download-button {
  margin-left: 10px;
  margin-right: 5px;
}
</style>
