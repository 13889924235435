<template>
  <v-card elevation="0">
    <v-tabs v-model="tab" background-color="primary" dark>
      <v-tab v-for="item in items" :key="item.id">
        {{ item.displayText }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.id" :transition=false>
        <v-card flat>
          <v-card-text v-if="item.id === 'accSummary'">
            <template>
              <div>
                <AccountSummary/>
              </div>
            </template>
          </v-card-text>
          <v-card-text v-else>
            <template>
              <InvoiceDetails/>
            </template>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import AccountSummary from "./AccountSummary";
import InvoiceDetails from "./InvoiceDetails";

export default {
  name: 'Tabs',
  components: {
    AccountSummary,
    InvoiceDetails
  },
  data() {
    return {
      tab: null,
      items: [
        {id: 'accSummary', displayText: 'Live Account summary'},
        {id: 'invDetails', displayText: 'Invoice details'}
      ],
    }
  },
}
</script>