<template>
  <v-card>
    <v-simple-table>
      <tbody style="font-weight: 400">
        <tr>
          <td>Credit limit:</td>
          <td style="text-align: right">{{ accountDetails.creditLimit }}</td>
        </tr>
        <tr>
          <td>Available credit:</td>
          <td style="text-align: right">{{ accountDetails.availableCredit }}</td>
        </tr>
        <tr>
          <td>Statement amount:</td>
          <td style="text-align: right">{{ accountDetails.statementAmount }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <div>
      <v-data-table :headers="headers" :items="getPopulatedList()" :hide-default-footer="true">
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'AccountSummary',
  accountDetailsList: [],
  headers: [],
  computed: {
    accountDetails() {
      return this.$store.getters.getAccountDetails;
    },
  },
  data() {
    return {
      headers: [
        { text: '180 Days', align: 'start', filterable: false, sortable: false, value: 'oneEightyDays' },
        { text: '150 Days', align: 'right', filterable: false, sortable: false, value: 'oneFiftyDays' },
        { text: '120 Days', align: 'right', filterable: false, sortable: false, value: 'oneTwentyDays' },
        { text: '90 Days', align: 'right', filterable: false, sortable: false, value: 'ninetyDays' },
        { text: '60 Days', align: 'right', filterable: false, sortable: false, value: 'sixtyDays' },
        { text: '30 Days', align: 'right', filterable: false, sortable: false, value: 'thirtyDays' },
        { text: 'Current', align: 'right', filterable: false, sortable: false, value: 'current' },
        { text: 'Credit In Account', align: 'right', filterable: false, sortable: false, value: 'creditInAccount' },
        { text: 'Amount Due', align: 'right', filterable: false, sortable: false, value: 'amountDue' },
      ],
    }
  },
  methods: {
    getPopulatedList() {
      this.accountDetailsList = [
        {
          oneEightyDays: this.accountDetails.oneEightyDays,
          oneFiftyDays: this.accountDetails.oneFiftyDays,
          oneTwentyDays: this.accountDetails.oneTwentyDays,
          ninetyDays: this.accountDetails.ninetyDays,
          sixtyDays: this.accountDetails.sixtyDays,
          thirtyDays: this.accountDetails.thirtyDays,
          current: this.accountDetails.current,
          creditInAccount: this.accountDetails.creditInAccount,
          amountDue: this.accountDetails.amountDue,
        }
      ];
      return this.accountDetailsList;
    }
  }
}
</script>

<style>
p {
  font-size: 18px;
  letter-spacing: 0.056em;
  font-weight: 400;
  margin-left: 10px;
}

.table {
  width: 100%;
  margin-top: 20px;
  margin-right: 10px;
}

th {
  font-size: 18px !important;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
