<template>
  <v-card elevation="0">
    <v-container class="align-content-center" fluid>
      <v-row align="center" justify="center" dense>
        <v-col cols="12" sm="8" md="4" lg="4">
          <v-card elevation="2" style="margin-top: 100px">
            <v-card-text class="justify-center">
              <v-card-title primary-title class="justify-center">
                <h4>Please login</h4>
              </v-card-title>
              <v-form @submit.prevent="login" ref="form">
                <v-text-field v-model="username" :rules="usernameRules" label="Enter your username" required
                  name="username" prepend-inner-icon="mdi-account" type="text" class="rounded-0" outlined @input="clearSnackbar"/>
                <v-text-field v-model="password" :rules="passwordRules" label="Enter your password" required
                  name="password" prepend-inner-icon="mdi-lock" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword" :type="showPassword ? 'text' : 'password'"
                  class="rounded-0" outlined @input="clearSnackbar"/>
                <v-btn block class="rounded-0" color="blue" dark x-large @click="login(username, password)">
                  Login
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <Loader />
    <Snackbar :message="errorText" :color="'red lighten-1'" :showSnackbar="snackbar" />
  </v-card>
</template>

<script>
import Loader from "@/components/Loader";
import Snackbar from "@/components/Snackbar";

export default {
  name: 'Login',
  components: {
    Loader,
    Snackbar,
  },
  data() {
    return {
      username: '',
      password: '',
      showPassword: false,
      snackbar: false,
      errorText: '',
      usernameRules: [
        v => !!v || 'Username is required',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
      ],
    }
  },
  methods: {
    login: async function (username, password) {

      localStorage.clear();
      sessionStorage.clear();

      if (!this.$refs.form.validate()) {
        return;
      }

      this.clearSnackbar();

      const loginCredentials = {
        username,
        password,
      };

      await this.$store.dispatch('resetState', this.state);
      await this.$store.dispatch('updateLoginCredentials', loginCredentials);
      await this.$store.dispatch('authenticateUser');

      if (this.authenticated) {
        await this.$store.dispatch('fetchAccountSummary')
          .then(() => {
            this.$router.push('/dashboard');
          });

      } else {
        this.errorText = this.$store.state.errorMessage;
        this.snackbar = true;
        this.$store.commit('LOADER', false);
      }
    },
    clearSnackbar() {
      this.snackbar = false;
    },
  },

  computed: {
    authenticated() {
      return this.$store.getters.isAuthenticated;
    }
  }
}
</script>

<style scoped>
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .v-row {
    margin-top: 100px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .v-row {
    margin-top: 200px;
  }

  .v-col {
    max-width: 400px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .v-row {
    margin-top: 250px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .v-row {
    margin-top: 300px;
  }
}
</style>
