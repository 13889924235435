<template>
  <v-app>
    <Navbar/>
    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from "@/components/Footer";
import axios from "axios";

export default {
  name: 'App',
  components: {
    Footer,
    Navbar
  },

  created() {
    axios.interceptors.request.use((config) => {
      this.$store.commit('LOADER', true);
      return config;
    }, (error) => {
      this.$store.commit('LOADER', false);
      return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
      this.$store.commit('LOADER', false);
      return response;
    }, (error) => {
      return new Promise((() => {
        this.$store.dispatch('logout').then(() => {
          this.$router.push('/');
        })
        throw error;
      }))
    });
  }
}
</script>
