<template>
    <v-snackbar v-model="showSnackbar" :color="color" absolute centered top>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </template>
  
  <script>
  export default {
    name: 'Snackbar',
    props: {
      message: {
        type: String,
        required: true
      },
      color: {
        type: String,
        default: 'red lighten-1'
      },
      showSnackbar: {
        type: Boolean,
        default: false
      }
    }
  }
  </script>  