<template>
  <nav>
    <v-app-bar outlined>
      <v-toolbar-title class="text-uppercase grey--text">
        <img src="../assets/hellerman-logo.png" alt="Hellerman Tyton" height="40" width="250" style="object-fit: contain;">
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text @click="logout" v-if="this.$store.getters.isAuthenticated">Logout</v-btn>
    </v-app-bar>
  </nav>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch('updateAutheticationState', false);
      this.$store.dispatch('resetState', this.state);
      this.$router.push('/');
    },
  }
}
</script>
