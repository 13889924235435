<template>
  <v-card>
    <Loader/>
    <v-card-title>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="invoiceDetails"
        :search="search"
        :items-per-page="10"
    >
      <template #item.view="{item}">
        <div v-if="item.viewKey === 'DOCUMENT_NOT_FOUND'">
          <a style="color: grey">View</a>
        </div>
        <div v-else>
          <a v-bind:href="getInvoiceUrl(item.viewKey)" target="_blank">View</a>
        </div>
      </template>
    </v-data-table>
    <Snackbar :message="errorText" :color="'red lighten-1'" :showSnackbar="snackbar" />
  </v-card>
</template>

<script>
import Loader from "@/components/Loader";
import Snackbar from "@/components/Snackbar";

export default {
  name: 'InvoiceDetails',
  components: {Loader, Snackbar},
  async created() {
    await this.$store.dispatch('fetchInvoiceDetails');
  },
  computed: {
    invoiceDetails() {
      return this.$store.getters.getInvoiceDetails;
    }
  },

  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Archive date',
          align: 'start',
          filterable: false,
          value: 'archiveDate',
        },
        {text: 'Invoice number', value: 'invoiceNumber'},
        {text: 'Invoice date', value: 'invoiceDate', filterable: false},
        {text: 'Amount', value: 'amount', align:'end'},
        {text: 'View', value: 'view', filterable: false, sortable: false},
      ],
      snackbar: false, 
      errorText: "", 
    }
  },

  methods: {
    getInvoiceUrl(viewKey) {
      return this.$store.getters.getServerViewKeyUrl.concat(viewKey);
    },
  },
  watch:{
    invoiceDetails(newVal) {
      if(newVal.length === 0) {
        this.snackbar = true; 
        this.errorText = this.$store.state.errorMessage;
      }
    }
  },
}
</script>

<style>

</style>
