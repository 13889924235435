<template>
  <v-card elevation="0">
    <div>
      <v-simple-table class="table-account">
        <tbody>
          <template v-if="Object.keys(accountSummary).length > 0">
            <tr>
              <td :class="[accountNumberClass]" class="account-info">{{ accountNumberLabel }}:</td>
              <td class="account-info">{{ accountSummary.accountNumber }}</td>
              <td class="account-info">{{ accountSummary.companyName }}</td>
            </tr>
            <tr>
              <td>{{ monthEndStatementLabel }}</td>
              <td style="text-align: left">{{ dateLabel }} {{ accountDetails.statementDate }}</td>
              <td style="text-align: left">
                <div v-if="accountDetails.latestStatementViewKey == null">
                  <v-btn color="grey" text>View</v-btn>
                </div>
                <div v-else>
                  <v-btn color="primary" dark :href="getStatementUrl(accountDetails.latestStatementViewKey)"
                    target="_blank">View</v-btn>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="3" class="text-center">
                <h2>No data found</h2>
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'Accounts',
  computed: {
    accountSummary() {
      /*
       TODO:
        1. If there are more than one account number, change the text field to be a drop down.
      */
      const summary = this.$store.getters.getAccountSummary
      if (!summary) {
        return {}
      }
      return summary
    },
    accountNumberLabel() {
      return this.$vuetify.breakpoint.mdAndDown ? 'Acc No' : 'Account number';
    },
    monthEndStatementLabel() {
      return this.$vuetify.breakpoint.mdAndDown ? 'Statement' : 'Month End Statement';
    },
    dateLabel() {
      return this.$vuetify.breakpoint.mdAndDown ? '' : 'Date:';
    },
    accountNumberClass() {
      return this.$vuetify.breakpoint.mdAndDown ? 'text-left' : '';
    },
    accountDetails() {
      return this.$store.getters.getAccountDetails;
    },
  },
  methods: {
    getStatementUrl(viewKey) {
      return this.$store.getters.getServerViewKeyUrl.concat(viewKey);
    },
  }
};
</script>

<style>
.table-account {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
  font-weight: 400;
}

td {
  font-size: 18px !important;
}

.text-center {
  text-align: center;
  vertical-align: middle;
}

.account-info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.text-left {
  text-align: left !important;
}
</style>
